<template>
  <div v-if="!codeSent" class="container">
    <h4 style="font-size: 24px">{{ $copy.phoneTitle }}</h4>
    <Message>{{ $copy.phoneMessage }}</Message>
    <PrimePhoneInput v-model="patient.smsPhoneNumber" class="text-center mobile" type="twilio" style="margin-bottom: 15px" />
    <El tag="p" class="text-secondary" :fontSize="14" fontWeight="500" align="center" :mt="0" :mb="25" :px="40">
      {{ $copy.phoneInfo }}
    </El>
    <PrimeButton @click="sendCode" size="large" block :disabled="!canSendCode" :pending="sending">Send Verification Code</PrimeButton>
  </div>
  <div v-else class="container">
    <h4 style="font-size: 24px">{{ $copy.codeTitle }}</h4>
    <Message>{{ $copy.codeMessage(patient.smsPhoneNumber) }}</Message>
    <input type="number" v-model="code" @input="verifyCode" class="form-control text-center code" placeholder="0000" />
    <div v-if="error" class="code-message error">
      {{ $copy.wrongCode }}
    </div>
    <div v-if="resent" class="code-message info">
      {{ $copy.newCode }}
    </div>
    <PrimeButton @click="resendCode" class="resend" color="secondary" :pending="sending">Resend Code</PrimeButton>
  </div>
</template>

<script>
import Message from "./Message";
import PrimePhoneInput from "@/components/Global/PrimePhoneInput";
import isValidPhoneNumber from "@/utils/FormHelpers/isValidPhoneNumber";

export default {
  name: "VerifyPhoneNumber",
  components: {
    Message,
    PrimePhoneInput
  },
  props: {
    api: Object,
    next: Object,
    patient: Object,
    token: String
  },
  data() {
    return {
      code: undefined,
      codeSent: false,
      error: false,
      resent: false,
      sending: false
    };
  },
  computed: {
    canSendCode() {
      return isValidPhoneNumber(this.patient.smsPhoneNumber, false);
    }
  },
  methods: {
    async sendCode() {
      try {
        this.error = false;
        this.sending = true;
        await this.api.sendCode(this.token, this.patient.smsPhoneNumber);
        this.codeSent = true;
      } catch (error) {
        khanSolo.log(error);
        this.$ionic.toastController
          .create({
            header: "Failed to send verification code",
            message: error,
            duration: 5000,
            position: "top"
          })
          .then(m => m.present());
      } finally {
        this.sending = false;
      }
    },
    async resendCode() {
      await this.sendCode();
      this.resent = true;
    },
    async verifyCode() {
      if (!this.code || this.code.length !== 4) {
        return;
      }

      try {
        this.resent = false;
        const result = await this.api.verifyCode(this.token, this.code);

        if (result.success) {
          this.$router.push(this.next);
        } else {
          this.error = true;
        }
      } catch (error) {
        khanSolo.log(error);
        this.$ionic.toastController
          .create({
            header: "Failed to verify code",
            message: error,
            duration: 5000,
            position: "top"
          })
          .then(m => m.present());
      }
    }
  }
};
</script>

<style scoped>
.container {
  padding: 30px 10px 0;
  text-align: center;
}
input.code {
  font-family: "PT Mono";
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 3px;
  width: 150px;
  height: 50px;
  margin: 0 auto 20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.code-message {
  font-size: 13px;
  font-weight: 700;
  margin-top: -13px;
  padding: 0 30px 15px;
}
.code-message.error {
  color: var(--ion-color-danger);
}
.code-message.info {
  color: var(--ion-color-primary);
}
button.resend {
  display: block;
  width: 140px;
  padding-top: 0;
  font-size: 12px;
  margin: 0 auto;
}
</style>
