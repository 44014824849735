<template>
  <El color="danger" v-bind="attrs">
    <slot />
  </El>
</template>

<script>
export default {
  name: "ErrorMessage",
  computed: {
    attrs() {
      const { fontSize = 13, fontWeight = "500", mt = -8, pl = 3, pb = 15, ...rest } = this.$attrs;
      return {
        fontWeight,
        fontSize,
        mt,
        pl,
        pb,
        ...rest
      };
    }
  }
};
</script>
