<template>
  <div>
    <El tag="h3" :mb="5">{{ $copy.title }}</El>
    <Message :mb="30">{{ $copy.message }}</Message>
    <ItemList>
      <Item v-for="(confirm, index) in $copy.confirmations" :key="index" :mb="25">
        <template #indicator>
          <PrimeCheckbox color="secondary" size="large" @input="setConfirmed($event, index)" :checked="confirmations[index]" />
        </template>
        <span>{{ confirm.text }}</span>
        <router-link class="link-primary" :to="{ name: confirm.to, query: { token } }">{{ confirm.link }}</router-link>
      </Item>
    </ItemList>
    <PrimeButton size="large" block :disabled="!allConfirmed" @click="finish">Agree and Finish</PrimeButton>
  </div>
</template>

<script>
import Message from "./Message";
import ItemList from "./ItemList";
import Item from "./Item";

export default {
  name: "EnrollmentConfirmation",
  components: {
    Message,
    ItemList,
    Item
  },
  props: {
    api: Object,
    next: Object,
    patient: Object,
    token: String
  },
  data() {
    return {
      confirmations: [],
      submitting: false
    };
  },
  computed: {
    allConfirmed() {
      return this.confirmations.every(conf => conf === true);
    }
  },
  created() {
    this.confirmations = this.$copy.confirmations.map(() => false);
  },
  methods: {
    setConfirmed(evt, index) {
      this.$set(this.confirmations, index, evt.target.checked);
    },
    async finish() {
      try {
        this.patient.communicationsPolicy = true;
        this.patient.eula = true;
        this.patient.privacyPolicy = true;

        this.submitting = true;
        await this.api.submit(this.token, this.patient);

        this.$router.push(this.next);
      } catch (error) {
        khanSolo.log(error);
        this.$ionic.toastController
          .create({
            header: "Failed to complete enrollment",
            message: error,
            duration: 5000,
            position: "top"
          })
          .then(m => m.present());
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>

<style scoped>
.link-primary {
  text-decoration: none;
}
.link-primary:hover {
  text-decoration: underline;
}
</style>
