<template>
  <El p="40px 20px 0">
    <h4 style="font-size: 24px" class="margin-bottom-thirty text-center">{{ $copy.title }}</h4>
    <input
      name="password"
      class="form-control text-center mobile"
      id="password"
      type="text"
      inputmode="numeric"
      @blur="validate"
      v-model="dob"
      v-mask="{
        mask: ['99/99/9999'],
        placeholder: 'MM/DD/YYYY',
        clearMaskOnLostFocus: false
      }"
    />
    <div v-if="error" class="dob-message error">
      {{ $copy.wrongDob }}
    </div>
    <PrimeButton :to="next" size="large" block :mt="30" :disabled="!verified">Next</PrimeButton>
  </El>
</template>

<script>
import moment from "moment";

export default {
  name: "VerifyDOB",
  props: {
    next: Object,
    patient: Object
  },
  data() {
    return {
      error: false
    };
  },
  computed: {
    dob: {
      get() {
        return this.$store.state.enrollment.dob;
      },
      set(value) {
        this.$store.commit("enrollment/dob", value);
      }
    },
    verified() {
      const provided = moment(this.dob, "MM-DD-YYYY").format("YYYY-MM-DD");
      const existing = moment(this.patient.dob, "YYYY-MM-DD");
      return existing.isSame(provided, "day");
    }
  },
  methods: {
    validate() {
      this.error = !this.verified;
    }
  }
};
</script>

<style scoped>
.dob-message {
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  margin-top: -8px;
  padding: 0 30px 15px;
}
.dob-message.error {
  color: var(--ion-color-danger);
}
</style>
