<template>
  <El tag="p" color="text.secondary" :mt="0" class="message" v-bind="$attrs">
    <slot />
  </El>
</template>

<script>
export default {
  name: "EnrollmentMessage"
};
</script>

<style scoped>
.message {
  font-weight: 500;
  line-height: 1.4;
}
</style>
