<template>
  <El :minHeight="210" :mb="20" v-bind="$attrs">
    <slot />
  </El>
</template>

<script>
export default {
  name: "ItemList",
  props: {
    spacing: { type: [String, Number], default: 15 }
  }
};
</script>

<style></style>
