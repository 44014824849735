<template>
  <div>
    <h3 class="margin-bottom-five">{{ $copy.title }}</h3>
    <Message>{{ $copy.message }}</Message>
    <ion-grid>
      <ion-row>
        <ion-col>
          <input name="firstName" type="text" v-model="patient.firstName" class="form-control mobile" placeholder="First name" @blur="onBlur" />
          <ErrorMessage>{{ getError("firstName") }}</ErrorMessage>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="margin-right-ten">
          <input name="lastName" type="text" v-model="patient.lastName" class="form-control mobile" placeholder="Last name" @blur="onBlur" />
          <ErrorMessage>{{ getError("lastName") }}</ErrorMessage>
        </ion-col>
        <ion-col style="max-width: 65px">
          <select name="suffix" class="form-control mobile" v-model="patient.suffix">
            <option v-for="option in $copy.suffixOptions" :key="option" :value="option">{{ option }}</option>
          </select>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <input name="email" type="email" v-model="patient.email" class="form-control mobile" placeholder="Email address" @blur="onBlur" />
          <ErrorMessage>{{ getError("email") }}</ErrorMessage>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <input name="emailConfirmation" type="email" v-model="emailConfirmation" class="form-control mobile" placeholder="Retype email address to confirm" @blur="onBlur" />
          <ErrorMessage>{{ getError("emailConfirmation") }}</ErrorMessage>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <select name="gender" class="form-control mobile" v-model="gender" required @blur="onBlur">
            <option value="" disabled hidden>Select gender</option>
            <option v-for="(val, key) in $copy.genderOptions" :key="key" :value="key">{{ val }}</option>
          </select>
          <ErrorMessage>{{ getError("gender") }}</ErrorMessage>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <PrimeButton :to="next" size="large" block :disabled="isValid">Next</PrimeButton>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import _ from "lodash";
import Message from "./Message";
import ErrorMessage from "./ErrorMessage";
import isValidEmail from "@/utils/FormHelpers/isValidEmail";

export default {
  name: "GetStarted",
  components: {
    Message,
    ErrorMessage
  },
  props: {
    next: Object,
    patient: Object
  },
  data() {
    return {
      isDirty: {},
      errors: {
        firstName: null,
        lastName: null,
        email: null,
        emailConfirmation: null,
        gender: null
      }
    };
  },
  computed: {
    emailConfirmation: {
      get() {
        return this.$store.state.enrollment.emailConfirmation;
      },
      set(value) {
        this.$store.commit("enrollment/emailConfirmation", value);
      }
    },
    gender: {
      get() {
        return this.patient.gender || "";
      },
      set(val) {
        this.$set(this.patient, "gender", val);
      }
    },
    hasErrors() {
      return !_.isEmpty(_.omitBy(this.errors, _.isNil));
    },
    isValid() {
      this.validate();
      return this.hasErrors;
    }
  },
  created() {
    this.patient.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.validate();
  },
  methods: {
    getError(field) {
      return this.isDirty[field] ? this.errors[field] : null;
    },
    onBlur(evt) {
      this.isDirty[evt.target.name] = true;
      this.validate();
    },
    validate() {
      const { firstName, lastName, email, gender } = this.patient;
      this.errors = {};

      if (!firstName) {
        this.errors.firstName = "First name is required";
      }
      if (!lastName) {
        this.errors.lastName = "Last name is required";
      }
      if (!email) {
        this.errors.email = "Email is required";
      } else if (!isValidEmail(email)) {
        this.errors.email = "Email is invalid";
      }
      if (email != this.emailConfirmation) {
        this.errors.emailConfirmation = "The emails do not match";
      }
      if (!gender) {
        this.errors.gender = "Gender is required";
      }
    }
  }
};
</script>

<style scoped>
select:invalid {
  color: var(--ion-color-medium);
}
</style>
