<template>
  <div>
    <El :mt="40" :mb="40">
      <img src="@/assets/logo.svg" alt="lumeris-logo" height="30px" />
    </El>
    <ion-slides ref="slides" pager class="pad-bottom-fourty">
      <ion-slide>
        <div>
          <h3>{{ $copy.features[0].title(displayName) }}</h3>
          <Message>
            {{ $copy.features[0].message_1 }}
            <strong class="text-headline">{{ patient.organizationName }}</strong
            >{{ $copy.features[0].message_2 }}
          </Message>
          <div class="text-center">
            <img src="@/assets/MyPCP-Enrollment-Slider-1.svg" alt="LumerisEngage feature" />
          </div>
        </div>
      </ion-slide>
      <ion-slide>
        <div>
          <h3>{{ $copy.features[1].title }}</h3>
          <Message>
            {{ $copy.features[1].message }}
          </Message>
          <div class="text-center">
            <img src="@/assets/MyPCP-Enrollment-Slider-2.svg" alt="LumerisEngage feature" />
          </div>
        </div>
      </ion-slide>
      <ion-slide>
        <div>
          <h3>{{ $copy.features[2].title }}</h3>
          <Message>
            {{ $copy.features[2].message }}
          </Message>
          <div class="text-center">
            <img src="@/assets/MyPCP-Enrollment-Slider-3.svg" alt="LumerisEngage feature" />
          </div>
        </div>
      </ion-slide>
    </ion-slides>
    <El align="center" :mt="50">
      <PrimeButton :to="next" size="large" :w="200">Get Started</PrimeButton>
    </El>
  </div>
</template>

<script>
import { formatDisplayName } from "@/utils/formatter";
import Message from "./Message";

export default {
  name: "InitialFeatures",
  components: {
    Message
  },
  props: {
    next: Object,
    patient: Object
  },
  data() {
    return {
      slideOpts: {
        autoplay: {
          delay: 5000
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  },
  computed: {
    displayName() {
      const { title, firstName, lastName, suffix } = this.patient;
      return formatDisplayName(title, firstName, lastName, suffix);
    }
  },
  mounted() {
    const slides = this.$refs.slides;
    this.$nextTick(() => {
      slides.options = this.slideOpts;
    });
  }
};
</script>

<style scoped>
ion-slide.swiper-slide {
  text-align: left;
}
</style>
