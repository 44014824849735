<template>
  <El align="center" :pt="40">
    <El tag="h3" :mb="30">{{ $copy.title }}</El>
    <El :mb="25">
      <img src="@/assets/MyPCP-Enrollment-Confirmation.svg" alt="LumerisEngage feature" />
    </El>
    <Message :px="20">{{ $copy.message }}</Message>
  </El>
</template>

<script>
import Message from "./Message";

export default {
  name: "EnrollmentFinished",
  components: {
    Message
  }
};
</script>

<style></style>
