<template>
  <div>
    <El tag="h3" :mb="5">{{ $copy.title }}</El>
    <Message :mb="30">{{ $copy.message }}</Message>
    <ItemList>
      <Item v-for="thing in $copy.things" :key="thing">
        <template #indicator>
          <ion-icon name="md-checkmark" color="success" class="checkmark" />
        </template>
        <span>{{ thing }}</span>
      </Item>
    </ItemList>
    <PrimeButton :to="next" type="button" color="primary" size="large" block>Next</PrimeButton>
  </div>
</template>

<script>
import Message from "./Message";
import ItemList from "./ItemList";
import Item from "./Item";

export default {
  name: "EnrollmentInformation",
  components: {
    Message,
    ItemList,
    Item
  },
  props: {
    next: Object
  }
};
</script>

<style scoped>
.checkmark {
  font-size: 28px;
}
</style>
