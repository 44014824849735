<template>
  <div>
    <h3>{{ $copy.title }}</h3>
    <Message :mb="30">{{ $copy.message }}</Message>
  </div>
</template>

<script>
import Message from "./Message";

export default {
  name: "EnrollmentNotFound",
  components: {
    Message
  }
};
</script>
