<template>
  <div v-if="!patient.phoneCallRequested">
    <h3>{{ $copy.title }}</h3>
    <Message :mb="30">{{ $copy.message }}</Message>
    <PrimePhoneInput v-model="phoneNumber" type="twilio" class="text-center mobile" placeholder="(123) 456-7890" v-mask="'(999) 999-9999'" />
    <PrimeButton @click="requestHelp" size="large" block :mt="20" :disabled="!isNumberValid" :pending="requesting">Request a Call</PrimeButton>
  </div>
  <div v-else>
    <h3>
      <ion-icon name="md-checkmark" color="success" class="success-checkmark"></ion-icon>
      {{ $copy.successTitle }}
    </h3>
    <Message :mb="30">{{ $copy.successMessage }}</Message>
    <PrimeButton @click="back" size="large" block>Return to Enrollment</PrimeButton>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { checkmark } from "ionicons/icons";
import Message from "./Message";
import PrimePhoneInput from "@/components/Global/PrimePhoneInput";
import isValidPhoneNumber from "@/utils/FormHelpers/isValidPhoneNumber";

addIcons({
  "md-checkmark": checkmark.md
});

export default {
  name: "NeedHelp",
  components: {
    Message,
    PrimePhoneInput
  },
  props: {
    api: Object,
    patient: Object,
    token: String
  },
  data() {
    return {
      phoneNumber: this.patient.voicePhoneNumber || this.patient.smsPhoneNumber,
      requesting: false,
      requestedHelp: false
    };
  },
  computed: {
    isNumberValid() {
      return isValidPhoneNumber(this.phoneNumber, false);
    }
  },
  methods: {
    async requestHelp() {
      try {
        this.requesting = true;
        this.patient.phoneCallRequested = true;
        this.patient.voicePhoneNumber = this.phoneNumber;

        await this.api.requestCall(this.token, this.phoneNumber);
      } catch (error) {
        khanSolo.log(error);
        this.$ionic.toastController
          .create({
            header: "Failed to request callback",
            message: error,
            duration: 5000,
            position: "top"
          })
          .then(m => m.present());
      } finally {
        this.requesting = false;
      }
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.form-control::placeholder {
  text-align: center;
}
.success-checkmark {
  font-size: 40px;
  vertical-align: text-bottom;
}
</style>
