<template>
  <El v-html="content" :font-size="14" />
</template>

<script>
import TemplateApi from "@/services/TemplateApi";

export default {
  name: "EnrollmentAgreement",
  data() {
    return {
      content: undefined
    };
  },
  async created() {
    try {
      const { data } = await TemplateApi.getByShortCode(this.$route.name);
      this.content = data.templateMessages.map(tm => tm.body).join("");
    } catch (error) {
      khanSolo.log(error);
      this.$ionic.toastController
        .create({
          header: `Failed to load ${this.$copy.headerTitle}`,
          message: error,
          duration: 5000,
          position: "top"
        })
        .then(m => m.present());
    }
  }
};
</script>

<style></style>
