<template>
  <El tag="ion-row" :mb="mb" v-bind="$attrs">
    <El tag="ion-col" size="1" :ml="5">
      <slot name="indicator" />
    </El>
    <El tag="ion-col" :ml="15" :pt="4" color="text.secondary" class="description">
      <slot />
    </El>
  </El>
</template>

<script>
import { addIcons } from "ionicons";
import { checkmark } from "ionicons/icons";

addIcons({
  "md-checkmark": checkmark.md
});

export default {
  name: "Item",
  props: {
    mb: { type: [String, Number], default: 15 }
  }
};
</script>

<style scoped>
.description {
  font-size: 15px;
  font-weight: 500;
}
</style>
